<template>
    <div class="py-4">
        <Loading :isLoading="isLoading" />
        <p class="text-xl text-cherryColor font-bold text-center mt-4 mb-8">Configuración de Folios Clientes ITBanks</p>

        <div class="w-full h-96 py-4">
            <p class="text-lg font-normal text-center mt-8 mb-8">Último Folio Registrado: <b class="text-xl">{{ lastFolioGenerated }}</b></p>
            <div class="grid w-full text-center">
                <input type="number" class="w-80 h-11 my-2 px-3 py-1 border border-gray shadow rounded-md mx-auto text-md outline-none focus:border-cherryColor" placeholder="Nuevo Folio" v-model="newFolio">
                <button class="w-56 h-11 my-6 px-4 py-1 bg-vividOrange text-white border border-vividOrange shadow rounded mx-auto font-normal text-lg outline-none focus:border-cherryColor" @click="message(newFolio)">Registrar</button>
            </div>
            
        </div>
    </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from 'vue';
import AdmissionRequest from "@/classes/AdmissionRequest";
import { getTokenDecoden } from "@/helpers/tokenauth";
import Swal from "sweetalert2";

export default {

    setup(){

        const admissionObject = ref(null);
        const apiToken = ref('');
        const userId = ref(0);
        const accountType = ref('');
        const lastFolioGenerated = ref('');
        const isLoading = ref(true);
        const newFolio = ref("");

        onBeforeMount( async () => {
            loadInfo();
            admissionObject.value = new AdmissionRequest();
            await getLastGeneratedFolio();
        });

        const loadInfo = () => {
            var auth = getTokenDecoden();

            accountType.value = auth.obj['role'];
            apiToken.value = sessionStorage.getItem("login");
            userId.value = auth.obj['id'];
        }

        const message = (folio) => {
            
            if(folio == ""){
                Swal.fire({
                    title: "Aviso",
                    html: "Es necesario agregar un folio",
                    icon: "info",
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "NO",
                });
                return;
            }

            Swal.fire({
                title: "Aviso",
                html: "¿Desea agrega un nuevo folio?",
                icon: "question",
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: '#E9AD3F',
                cancelButtonColor: '#E9AD3F',
                confirmButtonText: 'SI',
                cancelButtonText: "NO",
            }).then(async resp => {

                if(resp.isConfirmed){
                    await setItbnkFolioConfiguration(folio);
                }

            });

        }

        const getLastGeneratedFolio = async () => {
           await admissionObject.value.getLastGeneratedFolio(apiToken.value).then(resp => {
                lastFolioGenerated.value = resp.data.response.result.itbnks_id
           }).catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Aviso",
                    html: ((err.response.status == 500))?`Ha ocurrido un error al último folio generado`: err.response.data.response.message,
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Cancelar"
                });

                isLoading.value = false;
           });
        }

        const setItbnkFolioConfiguration = async (folio) => {

            isLoading.value = true;

            await admissionObject.value.setItbnkFolioConfiguration(apiToken.value, folio).then(async resp => {

                Swal.fire({
                    icon: "success",
                    title: "Aviso",
                    html: "El folio ha sido registrado exitosamente",
                    showCancelButton: false,
                    confirmButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok'
                });

                newFolio.value = "";
                await getLastGeneratedFolio();
                
            }).catch(err => {

                Swal.fire({
                    icon: "error",
                    title: "Aviso",
                    html: ((err.response.status == 500))?`Ha ocurrido un error al registrar el nuevo folio`: err.response.data.response.message,
                    showCancelButton: false,
                    reverseButtons: true,
                    confirmButtonColor: '#E9AD3F',
                    cancelButtonColor: '#E9AD3F',
                    confirmButtonText: 'Ok',
                    cancelButtonText: "Cancelar"
                });

                isLoading.value = false;
           });
        }

        return {
            lastFolioGenerated,
            setItbnkFolioConfiguration,
            newFolio,
            isLoading,
            message
        }
    }

}

</script>